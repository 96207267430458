import { lazy, Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';
import Footer from '../components/Footer';

import routes from './config';
import { Styles } from '../styles/styles';

const Router = () => {
    return (
        <Suspense fallback={null}>
            <Styles />
            <Switch>
                {routes.map((routeItem) => {
                    return (
                        <Route
                            key={routeItem.component}
                            path={routeItem.path}
                            exact={routeItem.exact}
                            component={lazy(
                                () => import(`../pages/${routeItem.component}`)
                            )}
                        />
                    );
                })}
            </Switch>
            {/* <Footer /> */}
        </Suspense>
    );
};

export default Router;
